<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Kode Divisi (Opsional)</label>
              <InputText
                v-model="form.div"
                class="w-full"
                input-class="w-full"
                maxlength="3"
              />
              <small
                v-if="
                  this.editMode == true &&
                  (this.form.div == '' || this.form.div == null)
                "
                class="p-error"
                >Kode divisi tidak boleh kosong</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Nama Divisi</label>
              <InputText
                v-model="form.name"
                :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="40"
              />
              <small
                v-if="
                  (v$.form.name.$invalid && submitted) ||
                  v$.form.name.$pending.$response
                "
                class="p-error"
                >{{ v$.form.name.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (
        this.editMode == true &&
        (this.form.div == '' || this.form.div == null)
      ) {
        return
      }

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
      },
    }
  },
}
</script>
